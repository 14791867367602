import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import slugify from '@sindresorhus/slugify';
import { customAlphabet } from 'nanoid';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// /**
//  * createHashFromString
//  * @via https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
//  */

// export async function createHashFromString(data: string, algorithm = 'SHA-256') {
//   if (!data) throw new Error('Failed to create hash. Data undefined.');
//   const encoder = new TextEncoder();
//   const hashBuffer = await crypto.subtle.digest(algorithm, encoder.encode(data))
//   const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
//   const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
//   return hashHex;
// }

/**
 * addCommas
 * @via Thanks ChatGPT
 */

export function addCommas(number: number | string) {
  if ( !['string', 'number'].includes(typeof number) ) return number;

  const num = `${number}`;
  const [whole, decimal] = num.split('.');
  let digits = whole.split('');
  let counter = 0;

  // Iterate through the digits from right to left
  for (var i = digits.length - 1; i >= 0; i--) {
    // Increment the counter
    counter++;

    // If the counter is a multiple of 3 and we're not at the leftmost digit, add a comma
    if (counter % 3 === 0 && i !== 0) {
      digits.splice(i, 0, ",");
    }
  }

  let joined = digits.join('');

  if ( decimal ) {
    joined = `${joined}.${decimal}`;
  }

  return joined;
}

/**
 * formatBytes
 */

interface FormatBytesOptions {
  type?: string;
  limit?: number;
  fixed?: number;
  commas?: boolean;
}

export function formatBytes(bytes: number, { type = 'kb', limit, fixed = 0, commas = true }: FormatBytesOptions = {}) {
  let amount = bytes;

  if ( typeof amount !== 'number' ) return amount;

  if ( limit && amount >= 1000000 ) {
    type = 'gb'
  } else if ( limit && amount >= limit * 1000 ) {
    type = 'mb';
  } else if ( limit && amount >= limit ) {
    type = 'kb';
  }

  if ( type === 'gb' ) {
    amount = amount / 1000000000;
  } else if ( type === 'mb' ) {
    amount = amount / 1000000;
  } else if ( type === 'kb' ) {
    amount = amount / 1000;
  }

  let formatted;

  if ( fixed > 0 && amount % 1 !== 0 ) {
    formatted = amount.toFixed(fixed)
  } else if ( fixed === 0 ) {
    formatted = Math.ceil(amount);
  }

  if ( commas && formatted ) {
    formatted = addCommas(formatted);
  }

  return `${formatted} ${type}`;
}

/**
 * getFileSize
 */

export async function getFileBlob(url: string) {
  const response = await fetch(url);

  if ( !response.ok ) {
    if ( response.status === 401 ) {
      throw new Error('UNAUTHORIZED');
    }
    throw new Error('UNKNOWN_ERROR');
  }

  const blob = await response.blob();

  return blob;
}

/**
 * downloadUrl
 */

interface DownloadUrlOptions {
  downloadBlob?: boolean;
}

export async function downloadUrl(url: string, filename: string = 'file', options?: DownloadUrlOptions) {
  const { downloadBlob = false } = options || {};
  let downloadUrl = url;

  if ( downloadBlob ) {
    const urls = url.split('/');
    const newUrl = `/image/${urls[urls.length - 1]}`
  
    const blob = await getFileBlob(newUrl);
    const blobUrl = URL.createObjectURL(blob);
    downloadUrl = blobUrl;
  }

  const a = document.createElement('a');

  a.href = downloadUrl;
  a.download = filename;

  function handleOnClick() {
    setTimeout(() => URL.revokeObjectURL(downloadUrl), 150);
    removeEventListener('click', handleOnClick);
  };

  a.addEventListener('click', handleOnClick, false);

  a.click();
}

export async function downloadBlob(base64: string, filename: string = 'file', options?: DownloadUrlOptions) {
  let downloadUrl = base64;

  const a = document.createElement('a');

  a.href = downloadUrl;
  a.download = filename;

  function handleOnClick() {
    setTimeout(() => URL.revokeObjectURL(downloadUrl), 150);
    removeEventListener('click', handleOnClick);
  };

  a.addEventListener('click', handleOnClick, false);

  a.click();
}

export async function copyFromUrl(url: string) {
  const urls = url.split('/');
  const newUrl = `/image/${urls[urls.length - 1]}`
  const blob = await getFileBlob(newUrl);
  // 将 Blob 对象复制到剪贴板
  await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]);
}

export async function copyBlob(base64: string) {
  try {
    await navigator.clipboard.read(); // denied permission will throw error can catch exception in under catch
    console.log('Clipboard read permission granted.'); //granted permission
  } catch (error) {
    console.log('ERROR:: Clipboard read permission denied:', error);
  }

  const img = new Image();
  img.src = base64;

  // 等待图片加载完成
  await new Promise((resolve) => {
    img.onload = resolve;
  });

  // 将 Image 元素转换为 Blob 对象
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = img.width;
  canvas.height = img.height;
  ctx!.drawImage(img, 0, 0);
  // 将 Blob 对象复制到剪贴板
  await navigator.clipboard.write([new ClipboardItem({ 'image/png': await new Promise((resolve) => canvas.toBlob(resolve as BlobCallback, 'image/png')) })]);
}

/**
 * addNumbers
 */

export function addNumbers(numbers: Array<number>) {
  return numbers.reduce((prev, curr) => prev + curr, 0);
}


export const updateSearchParams = (type: string, value: string) => {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(type, value);

  const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

  return newPathname;
}

export function nanoid(length = 8) {
  return customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    length,
  )()
}

export function sanitize(text: string) {
  return slugify(text, { decamelize: false, lowercase: false, separator: '-' })
}